<template>
  <div name="index">
    <div class="container" style="margin: 0 10px">
      <div class="tabs">
        <!-- Third tab, should have "tab" class and unique id attribute -->
        <div class="tab" id="tab3">
          <div class="tab3-top">
            <div
              class="item"
              v-for="item in areaList"
              @click="getArea2(item.id, item.name, true)"
              :key="item.id"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="tab3-top" v-if="area2List && area2List.length > 0">
            <!-- <div
              class="item area2List"
              v-for="item in area2List"
              @click="getArea3(item.id, item.name, true)"
              :key="item.id"
            >
              {{ item.name }}
            </div> -->
			<div
			  class="item area2List"
			  @click="getArea3(area2List[0].id, area2List[0].name, true)"
			>
			  {{ area2List[0].name }}
			</div>
          </div>
          <div v-if="area3List">
            <ul class="orgfundlist">
              <li
                v-for="item in area3List"
                @click="getArea4(item.id, item.name, true)"
                :key="item.id"
                v-show="
                  getOrgInfoShowId == null || getOrgInfoShowId === item.id
                "
                class="item area3List"
              >
                {{ item.name }}
              </li>
              <li
                class="item"
                v-show="getOrgInfoShowId != null"
                @click="backOrg3InfoTab"
                style="color: #fff"
              >
                <span>&lt;&lt;{{ $t('public.goBack') }}</span>
              </li>
            </ul>
          </div>
          <div v-if="area4List">
            <ul class="orgfundlist">
              <li
                v-for="item in area4List"
                @click="getArea4Info(item.code, item.name, true)"
                :key="item.code"
                class="item area4List"
                :class="getArea4InfoId === item.code ? 'active' : ''"
              >
                {{ item.name }}
              </li>
            </ul>
          </div>
          <div class="area4info" v-if="showOrgInfo">
            <div class="tab3-top-new">
              <a
                href="javascript:void(0);"
                @click="changeTab3Tab(1, '资金', true)"
                :class="tab3_active === 1 || tab3_active === 5 ? 'active' : ''"
                >{{ $t('index.capital') }}</a
              >
              <a
                href="javascript:void(0);"
                @click="changeTab3Tab(2, '资产', true)"
                :class="tab3_active === 2 ? 'active' : ''"
                >{{ $t('index.assets') }}</a
              >
              <a
                href="javascript:void(0);"
                @click="changeTab3Tab(3, '资源', true)"
                :class="tab3_active === 3 ? 'active' : ''"
                >{{ $t('index.resources') }}</a
              >
              <a
                href="javascript:void(0);"
                @click="changeTab3Tab(4, '村(居)务', true)"
                :class="tab3_active === 4 ? 'active' : ''"
                >{{ $t('index.villageAffairs') }}</a
              >
            </div>
            <div class="tab3-top-new-table-1">
              <div
                class="tab3-top-2-new"
                v-if="tab3_active === 1 || tab3_active === 5"
              >
                <a
                  href="javascript:void(0);"
                  @click="changeTab3Tab(1, '收入', true)"
                  :class="tab3_active === 1 ? 'active' : ''"
                >
                  {{ $t('index.capitalItem.inCome') }}
                </a>
                <a
                  href="javascript:void(0);"
                  @click="changeTab3Tab(5, '支出', true)"
                  :class="tab3_active === 5 ? 'active' : ''"
                >
                  {{ $t('index.capitalItem.expend') }}
                </a>
              </div>
              <!-- 音频播放 -->
              <audio :src="audioSrc" autoplay style="display: none"></audio>
              <div v-if="tab3_active === 1 || tab3_active === 5">
                <!-- 收入列表 -->
                <div v-if="org3List.length > 0">
                  <div
                    v-for="(item, index) in org3List.slice(0, 2)"
                    :key="item.id"
                    class="list-box"
                  >
                    <div style="display: flex">
                      <div class="title-box" style="flex: 1">
                        <span class="title">{{ item.abstracts }}</span>
                      </div>
                      <div class="icon-box">
                        <img
                          style="width: 20px; margin: 0 5px"
                          class="icon"
                          src="../assets/icon/play.png"
                          v-show="!item.playStatus"
                          @click="playControl(index)"
                        />
                        <img
                          style="width: 20px; margin: 0 5px"
                          class="icon"
                          src="../assets/icon/play_selected.png"
                          v-show="item.playStatus"
                          @click="playControl(index)"
                        />
                        <!-- <img
                          style="width: 20px; margin: 0 5px" class="icon" src="../assets/icon/policy.png"
                          @click="seePolicy(index)"
                        /> -->
                      </div>
                    </div>
                    <div>
                      <span>{{ $t('index.capitalItem.category') }}：</span
                      ><strong>{{ item.subjects }}（{{ item.type }}）</strong>
                    </div>
                    <div>
                      <span>{{ $t('index.capitalItem.amount') }}：</span>
                      <span class="money">{{ item.money }}元</span>
                      <span class="tousu_btn" @click="show(item.id)">
                        {{ $t('public.fastComplaint') }}
                      </span>
                    </div>
                    <div>
                      <span>{{ $t('index.capitalItem.time') }}：</span>
                      <strong>{{ item.time }}</strong>
                    </div>
                    <div><span>{{ $t('index.capitalItem.supply') }}：</span>{{ item.content || "-" }}</div>
                    <div class="imglist" v-if="item.photo">
                      <img
                        :src="`${src}?resize=100x100`"
                        v-for="(src, index) in item.photo.split(',')"
                        @click="previewImg(item, 'photo')"
                        class="org_list_img"
                        :key="index"
                      />
                    </div>
                  </div>
                  <div style="display: flex; justify-content: flex-end">
                    <div @click="moreIncome">{{ $t('public.more') }}>></div>
                  </div>
                </div>
                <div v-else class="no-data">{{ $t('public.noData') }}</div>
              </div>
              <div v-if="tab3_active === 2">
                <!--资产列表 -->
                <div v-if="org3List.length > 0">
                  <ul
                    v-for="(item, index) in org3List.slice(0, 2)"
                    :key="item.id"
                  >
                    <li>
                      <!-- <span class="title">
                        {{ item.name }}（{{ item.ismanagement }}）
                      </span> -->
                      <div style="display: flex">
                        <div class="title-box" style="flex: 1">
                          <span class="title"
                            >{{ item.name }}（{{ item.ismanagement }}）</span
                          >
                        </div>
                        <div class="icon-box">
                          <img
                            style="width: 20px; margin: 0 5px"
                            class="icon"
                            src="../assets/icon/play.png"
                            v-show="!item.playStatus"
                            @click="playControl(index, 1)"
                          />
                          <img
                            style="width: 20px; margin: 0 5px"
                            class="icon"
                            src="../assets/icon/play_selected.png"
                            v-show="item.playStatus"
                            @click="playControl(index, 1)"
                          />
                          <!-- <img
                            style="width: 20px; margin: 0 5px" class="icon" src="../assets/icon/policy.png"
                            @click="seePolicy(index)"
                          /> -->
                        </div>
                      </div>
                    </li>
                    <li>
                      <span>{{ $t('index.assetsItem.type') }}：</span>{{ item.model }}&nbsp;&nbsp;
                      <span>{{ $t('index.assetsItem.quantity') }}：</span>{{ item.number }}&nbsp;&nbsp;
                      <span>{{ $t('index.assetsItem.status') }}：</span>{{ item.status }}
                    </li>
                    <li>
                      <span>{{ $t('index.assetsItem.money') }}：</span><span class="money">{{ item.price }}元</span>
                      <span class="tousu_btn" @click="show(item.id)">{{ $t('public.fastComplaint') }}</span>
                    </li>
                    <li>
                      <span>{{ $t('index.assetsItem.purchaseTime') }}：</span><strong>{{ item.buytime }}</strong>
                    </li>
                    <li><span>{{ $t('public.currentLocation') }}：</span>{{ item.position }}</li>
                    <li class="imglist" v-if="item.picture">
                      <!--                    <img-->
                      <!--                      :src="src"-->
                      <!--                      preview="1"-->
                      <!--                      v-for="src in item.picture.split(',')"-->
                      <!--                      class="org_list_img"-->
                      <!--                      :key="src"-->
                      <!--                    />-->
                      <img :src="`${src}?resize=100x100`"
                        v-for="(src, index) in item.picture.split(',')"
                        @click="previewImg(item, 'picture')"
                        class="org_list_img"
                        :key="index"
                      />
                    </li>
                  </ul>
                  <div style="display: flex; justify-content: flex-end">
                    <div @click="moreAssets">{{ $t('public.more') }}>></div>
                  </div>
                </div>
                <div v-else class="no-data">{{ $t('public.noData') }}</div>
              </div>
              <div v-if="tab3_active === 3">
                <!--资源列表-->
                <div v-if="org3List.length > 0">
                  <ul
                    v-for="(item, index) in org3List.slice(0, 2)"
                    :key="item.id"
                  >
                    <li>
                      <!-- <span class="title">{{ item.name }}</span> -->
                      <div style="display: flex">
                        <div class="title-box" style="flex: 1">
                          <span class="title">{{ item.name }}</span>
                        </div>
                        <div class="icon-box">
                          <img
                            style="width: 20px; margin: 0 5px"
                            class="icon"
                            src="../assets/icon/play.png"
                            v-show="!item.playStatus"
                            @click="playControl(index, 2)"
                          />
                          <img
                            style="width: 20px; margin: 0 5px"
                            class="icon"
                            src="../assets/icon/play_selected.png"
                            v-show="item.playStatus"
                            @click="playControl(index, 2)"
                          />
                          <!-- <img
                            style="width: 20px; margin: 0 5px" class="icon" src="../assets/icon/policy.png"
                            @click="seePolicy(index)"
                          /> -->
                        </div>
                      </div>
                    </li>
                    <li>
                      <span>{{ $t('index.resourcesItem.type') }}：</span>{{ item.type }}&nbsp;&nbsp;
                      <span>{{ $t('public.time') }}：</span>{{ item.time }}
                    </li>
                    <li>
                      <span>{{ $t('index.resourcesItem.operateMode') }}：</span>{{ item.operation }}&nbsp;&nbsp;
                      <span class="tousu_btn" @click="show(item.id)" >{{ $t('public.fastComplaint') }}</span>
                    </li>
                    <li>
                      <span>{{ $t('index.resourcesItem.devSituation') }}：</span>{{ item.situation }}
                    </li>
                    <li class="imglist" v-if="item.pic">
                      <img
                        :src="`${src}?resize=100x100`"
                        v-for="(src, index) in item.pic.split(',')"
                        @click="previewImg(item, 'pic')"
                        class="org_list_img"
                        :key="index"
                      />
                    </li>
                  </ul>
                  <div style="display: flex; justify-content: flex-end">
                    <div @click="moreResource">{{ $t('public.more') }}>></div>
                  </div>
                </div>
                <div v-else class="no-data">{{ $t('public.noData') }}</div>
              </div>
              <div v-if="tab3_active === 4">
                <!--村务列表-->
                <div v-if="org3List.length > 0">
                  <ul v-for="item in org3List.slice(0, 2)" :key="item.id">
                    <li>
                      <span class="title">{{ item.title }}</span>
                    </li>
                    <li><span>{{ $t('public.time') }}：</span>{{ item.time }}</li>
                    <li class="imglist" v-if="item.pic">
                      <img
                        :src="`${src}?resize=100x100`"
                        v-for="(src, index) in item.pic.split(',')"
                        @click="previewImg(item, 'pic')"
                        class="org_list_img"
                        :key="index"
                      />
                    </li>
                  </ul>
                  <div style="display: flex; justify-content: flex-end">
                    <div @click="moreAffairs">{{ $t('public.more') }}>></div>
                  </div>
                </div>
                <div v-else class="no-data">{{ $t('public.noData') }}</div>
              </div>
              <tousu_modal
                :moneyId="moneyId"
                :remark="remark"
                :addsan="addsan"
              ></tousu_modal>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <p v-if="todayView">
          {{ $t('footer.todayVisits') }}：{{ todayView }} &nbsp;&nbsp;{{ $t('footer.cumulativeVisits') }}：{{ totalView }}
        </p>
        <p>{{ $t('footer.producer') }}：{{ $t('footer.producerName') }}</p>
        <!-- <p>{{ $t('footer.contactAddress') }}：{{ $t('footer.contactAddressName') }}</p> -->
      </div>
    </div>

    <VoicePlay :content="ZY_writtenWords" />
  </div>
</template>
<script>
import CryptoJS from "../utils/CryptoJS";
import BASE from "../utils/base";
import tousu_modal from "../components/tousu_modal";
import VoicePlay from "@/components/VoicePlay";
import { ImagePreview, Toast  } from "vant";
import { voiceToString } from "../utils/util.js"
export default {
  name: "app",
  components: {
    tousu_modal,
    VoicePlay,
  },
  mounted() {
	
  },
  created() {
	// voiceToString();
    // when the Vue app is booted up, this is run automatically.
    let data = "DpHxKkMbPymRYCFi" + new Date().getTime();
    let _data = CryptoJS.encrypt(data);
    this.sign = _data;
    let that = this;

    // 获取浏览量
    this.axios
      .get(
        BASE.genUrl("zxCommViews/list", {
          type: "%E6%99%BA%E6%85%A7%E7%9B%91%E7%9D%A3",
        })
      ) // "http://test.xjiot.link/jeecg-boot/api/zxCommViews/list?apikey=DpHxKkMbPymRYCFi&sign=&type=%E6%99%BA%E6%85%A7%E7%9B%91%E7%9D%A3")
      .then((res) => {
        console.log("浏览量", res);
        let data = res.data;
        if (data.result) {
          that.todayView = data.result.today;
          that.totalView = data.result.total;
        }
      });

    // 区域查询
    this.getArea();

    // 投诉
    this.axios
      .get(
        BASE.genUrl("zxSuperviseLetter/list", {
          pageNo: 1,
          pageSize: 6,
          state: "完结",
        })
      )
      .then((res) => {
        console.log("投诉", res);
        let data = res.data;
        if (data.result) {
          that.tousuNews = data.result.records;
        }
      });

    // 系统所有资金及人员
    this.axios
      .get(
        BASE.genUrl("zxSuperviseComm/getDetailAll", { address: " ", type: " " })
      )
      .then((res) => {
        console.log("系统所有资金及人员", res);
        let data = res.data;
        if (data.result) {
          console.log(data.result);
          that.getDetailAll = data.result[0];
        }
      });
    // 组织机构查询
    this.getOrg(" ");
  },
  watch: {
    sfzNo: function (n1) {
      this.sfzNomd5 = CryptoJS.encryptsfz(n1);
    },
    searchZc: function (n) {
      // this.searchZcParams = "*" + n + "*";
      this.searchZcParams = n;
    },
    searchXm: function (n) {
      // this.searchXmParams = "*" + n + "*";
      this.searchXmParams = n;
    },
    $route(to) {
      if (to.params.id) this.$router.go(0);
    },
    zctype: function () {
      this.zcTypeList();
    },
  },
  computed: {
    
  },
  data() {
    return {
      result: [],
      active: 1,
      tab2_active: 0,
      tab3_active: 1,
      todayView: null,
      totalView: null,
      zhengceNews: [],
      xiangmuNews: [],
      tousuNews: [],
      orgList: [],
      org2Info: null,
      org3Info: null,
      areaList: [],
      area2List: [],
      area3List: [],
      area4List: [],
      // getArea3ListShowId: null,
      orgFund: [],
      orgTabFund: [],
      areaFund: [],
      sfzNo: "",
      sfzNomd5: null,
      deptName: "",
      getDetailAll: {},
      org2InfoItemShowId: null,
      getFundInfoShowId: null,
      getOrgInfoShowId: null,
      showOrgInfo: false,
      zctype: "全部政策",
      searchZc: "",
      searchZcParams: "",
      searchXm: "",
      searchXmParams: "",
      moneyId: "",
      org3List: [],
      getArea4InfoId: "",
      pageSize: 10,
      pageNo: 1,
      addsan: true,
      showLoadSpin: 0,
      isRefresh: false,
      // 播放状态控制
      playStatus: true,
      // 播放内容
      audioSrc: "",
      // 查看政策弹出框
      showPopup: false,
      // 弹出框数据
      popupData: {},
      // 语音播报组件征用字段
      ZY_writtenWords: "",
	  loading: false,
	  finished: false,
	  // 请求参数
	  requestData: {
	    pageNo: 1,
	    pageSize: 6
	  },
    };
  },
  activated() {
    this.moreFunddetail();
  },
  methods: {
    moreFunddetail() {
      // 获取地址栏参数
      const params = this.$route.params;

      // console.log('获取地址栏参数')
      // console.log(this.$route.params, '--------------')
      // console.log(CryptoJS.decryptsfz(params.id), this.sfzNomd5)
      // console.log('获取地址栏参数')
      if (params.id) {
        this.sfzNo = CryptoJS.decryptsfz(params.id);

        this.$router.push({
          name: "funddetail",
          params: { idcard: params.id, name: "" },
        });
      } else {
        // console.log(456456456)
      }
    },
    moreIncome() {
      this.$router.push({
        name: "income",
        query: { id: this.tab3_active, code: this.getArea4InfoId },
      });
    },
    moreAssets() {
      this.$router.push({
        name: "assets",
        query: { id: this.tab3_active, code: this.getArea4InfoId },
      });
    },
    moreAffairs() {
      this.$router.push({
        name: "affairs",
        query: { id: this.tab3_active, code: this.getArea4InfoId },
      });
    },
    moreResource() {
      this.$router.push({
        name: "resource",
        query: { id: this.tab3_active, code: this.getArea4InfoId },
      });
    },
    show(id) {
      this.moneyId = id;
      this.$modal.show("modal");
    },
    hide() {
      this.$modal.hide("modal");
    },
    uploadImage(e) {
      let that = this;
      var formData = new FormData();
      var files = e.target.files;
      for (var i = 0; i < files.length; i++) {
        formData.append("file", files[i]);
      }
      this.axios
        .post(BASE.genUrl("zxSuperviseComm/idcardupdate"), formData, {
          method: "post",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          transformRequest: [
            function (data) {
              return data;
            },
          ],
          onUploadProgress: function (e) {
            var percentage = Math.round((e.loaded * 100) / e.total) || 0;
            if (percentage < 100) {
              console.log(percentage + "%"); // 上传进度
              that.sfzNo = "查询中..." + percentage + "%";
            }
          },
        })
        .then(function (resp) {
          if (resp.data.code === 200) {
            that.sfzNo = resp.data.result.cardid;
            that.$router.push({
              name: "funddetail",
              params: { idcard: CryptoJS.encryptsfz(that.sfzNo) },
            });
          } else {
            alert(resp.data.message);
          }
        });
    },
    // 校验身份证号
    IdentityCodeValid(code) {
      code = code.toUpperCase();
      var city = {
        11: "北京", 12: "天津", 13: "河北", 14: "山西", 15: "内蒙古", 21: "辽宁",
        22: "吉林", 23: "黑龙江 ", 31: "上海", 32: "江苏", 33: "浙江", 34: "安徽",
        35: "福建", 36: "江西", 37: "山东", 41: "河南", 42: "湖北 ", 43: "湖南", 
        44: "广东", 45: "广西", 46: "海南", 50: "重庆", 51: "四川", 52: "贵州",
        53: "云南", 54: "西藏 ", 61: "陕西", 62: "甘肃", 63: "青海", 64: "宁夏",
        65: "新疆", 71: "台湾", 81: "香港", 82: "澳门", 91: "国外 ",
      };
      // var tip = "";
      var pass = true;

      if (
        !code ||
        !/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X|x)$/i.test(
          code
        )
      ) {
        //if(!code || !/(^\d{17}(\d|X|x)$)/i.test(code)){
        // tip = "身份证号格式错误";
        pass = false;
      } else if (!city[code.substr(0, 2)]) {
        // tip = "地址编码错误";
        pass = false;
      } else {
        //18位身份证需要验证最后一位校验位
        if (code.length == 18) {
          code = code.split("");
          //∑(ai×Wi)(mod 11)
          //加权因子
          var factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
          //校验位
          var parity = [1, 0, "X", 9, 8, 7, 6, 5, 4, 3, 2];
          var sum = 0;
          var ai = 0;
          var wi = 0;
          for (var i = 0; i < 17; i++) {
            ai = code[i];
            wi = factor[i];
            sum += ai * wi;
          }
          // var last = parity[sum % 11];
          if (parity[sum % 11] != code[17]) {
            // tip = "校验位错误";
            pass = false;
          }
        }
      }
      //if(!pass) mui.toast(tip);
      return pass;
    },
    // 校验是否包含汉字
    fullNameValid(name) {
      let reg = /^[\u2E80-\u9FFF]|·+$/; //Unicode编码中的汉字范围

      return reg.test(name);
    },
    bindFullNameQuery() {
      const that = this
      let routerParam = { name: "funddetail", params: { idcard: this.sfzNomd5 } }

      Toast.loading({
        message: '加载中...',
        duration: 0,
        forbidClick: true,
      });

      // 查询数据
      // const len = 1
      this.axios
        .get(BASE.genUrl("zxSuperviseMoney/onebyname", { name: this.sfzNomd5 }))
        .then((res) => {
          Toast.clear();
          console.log(res.data.result.total)
          const len = res.data.result.total ?? 0
          if (len > 1) routerParam.name = "fundPersonList"

          that.$router.push(routerParam);
        }).catch(err => {
          Toast.clear();
        });

      
    },
    backOrg3InfoTab() {
      this.getOrgInfoShowId = null;
      this.orgTabFund = [];
      this.area4List = [];
    },
    getOrg2Info(oid, content = "", play = false) {
      // 添加公共语音播报
      if (play) this.ZY_writtenWords = content;

      let that = this;
      this.axios
        .get(
          BASE.genUrl("zxSuperviseComm/getDepart", {
            orgcode: "6523",
            parentid: oid,
          })
        )
        .then((res) => {
          console.log("组织机构2级查询", res);
          let data = res.data;
          if (data.result) {
            that.org2Info = data.result;
            that.org3Info = [];

            that.getOrg3Info(data.result[0].id, data.result[0].depart_name);
          }
        });
    },
    getOrg3Info(oid, dept, play = false) {
      // 添加公共语音播报
      if (play) this.ZY_writtenWords = dept;

      let that = this;
      this.deptName = dept;
      this.axios
        .get(
          BASE.genUrl("zxSuperviseComm/getDepartNo", {
            orgcode: "6523",
            parentid: oid,
          })
        )
        .then((res) => {
          console.log("组织机构3级查询", res);
          let data = res.data;
          if (data.result) {
            that.org3Info = data.result;
          }
        });
    },
    changeTab(nav, content = "", play = false) {
      // 添加公共语音播报
      if (play) this.ZY_writtenWords = content;

      this.active = nav;
      // this.area2List = [];
      // this.area3List = [];
      this.area4List = [];
      this.getOrgInfoShowId = null;
      this.showOrgInfo = false;
    },
    changeTab2Tab(nav, content = "", play = false) {
      // 添加公共语音播报
      if (play) this.ZY_writtenWords = content;

      this.tab2_active = nav;
    },
    changeTab3Tab(nav, content = "", play = false) {
      // 添加公共语音播报
      if (play) this.ZY_writtenWords = content;

      let remarkList = {
        1: "收",
        2: "资产",
        3: "资源",
        4: "村务",
        5: "支",
      };
      if (this.tab3_active != nav) {
        this.pageNo = 1;
        this.pageSize = 10;
      }
      this.remark = remarkList[nav];
      this.tab3_active = nav;
      this.$previewRefresh();
      this.getTab3Info(nav);
    },
    trim(str, all = false) {
      return all ? str.replace(/\s+/g, "") : str.replace(/(^\s*)|(\s*$)/g, "");
    },
    previewImg(record, key) {
      const images = record[key].split(",").map((item) => this.trim(item));
      ImagePreview({ images, closeable: true });
    },
    getTab3Info(id) {
      let that = this;
      let params = {
        1: {
          url: "zxSuperviseSMoney/list",
          options: { inorout: "收入" },
        },
        2: {
          url: "zxSuperviseSAssets/list",
          options: {},
        },
        3: {
          url: "zxSuperviseSResources/list",
          options: {},
        },
        4: {
          url: "zxSuperviseSAffairs/list",
          options: {},
        },
        5: {
          url: "zxSuperviseSMoney/list",
          options: { inorout: "支出" },
        },
      };
      console.log(this.getArea4InfoId, "getTab3Info id");
      this.axios
        .get(
          BASE.genUrl(
            params[id].url,
            Object.assign(params[id].options, {
              sysOrgCode: this.getArea4InfoId,
              pageNo: this.pageNo,
              pageSize: this.pageSize,
            })
          )
        )
        .then((res) => {
          let data = res.data;
          if (data.result) {
            let org3List = data.result.records;

            for (const item of org3List) {
              item.playStatus = false;
            }

            that.org3List = org3List;
          }
        });
    },
    getOrg(data) {
      let that = this;
      this.axios
        .get(
          BASE.genUrl("zxSuperviseComm/getDepart", {
            orgcode: "6523",
            parentid: data,
          })
        )
        .then((res) => {
          console.log("组织机构查询", res);
          let data = res.data;
          if (data.result) {
            that.orgList = data.result;
            that.getOrg2Info(data.result[0].id);
          }
        });
    },
    // 区域查询
    getArea() {
      const that = this;
      // 区域查询
      this.axios
        .get(
          BASE.genUrl("zxCommAddress/rootList", {
            pid: "1d2bb3af77ee4ae4bf8871067233febf",
          })
        )
        .then((res) => {
          console.log("区域查询", res);
          let data = res.data;
          if (data.result) {
            data.result.records.forEach((item) => {
              if (item.name === "昌吉回族自治州") {
                that.areaList = [item];
                that.getArea2(item.id);
              }
            });
            // that.areaList = data.result.records;
          }
        });
    },
    getArea2(id, content, play = false) {
      // 添加公共语音播报
      if (play) this.ZY_writtenWords = content;

      let that = this;
      this.area3List = [];
      this.axios
        .get(BASE.genUrl("zxCommAddress/rootList", { pid: id }))
        .then((res) => {
          console.log("区域查询222", res, id);
          let data = res.data;
          console.log("区域查询222", data.result);
          if (data.result) {
            console.log(data.result.records);
            that.area2List = data.result.records;
            that.getArea3(
              data.result.records[0].id,
              data.result.records[0].name
            );
          }
        });
    },
    getArea3(id, name, play = false) {
      // 添加公共语音播报
      if (play) this.ZY_writtenWords = name;

      let that = this;
      this.axios
        .get(BASE.genUrl("zxCommAddress/rootList", { pid: id }))
        .then((res) => {
          console.log("区域二级查询", res);
          let data = res.data;
          if (data.result) {
            for (let i = 0; i < data.result.records.length; i++) {
              data.result.records[i].address =
                name + data.result.records[i].name;
            }
            that.area3List = data.result.records;
          }
        });
    },
    getArea4(id, name, play = false) {
      // 添加公共语音播报
      if (play) this.ZY_writtenWords = name;

      let that = this;
      this.getOrgInfoShowId = id;
      this.axios
        .get(BASE.genUrl("zxCommAddress/rootList", { pid: id }))
        .then((res) => {
          console.log("区域三级查询", res);
          let data = res.data;
          if (data.result) {
            for (let i = 0; i < data.result.records.length; i++) {
              data.result.records[i].address =
                name + data.result.records[i].name;
            }
            that.area4List = data.result.records;
          }
        });
    },
    getArea4Info(id, text, play = false) {
      console.log("getArea4Info", text, play);
      // 添加公共语音播报
      if (play) this.ZY_writtenWords = text;

      console.log(id);
      this.getArea4InfoId = id;
      this.showOrgInfo = true;
      this.org3List = [];
      this.changeTab3Tab(1);
    },
    goPrev: function () {
      console.log(this.pageNo, this.pageSize, this.tab3_active);
      if (this.pageNo > 1) {
        this.pageNo = this.pageNo - 1;
        console.log("prev", this.pageNo);
        this.changeTab3Tab(this.tab3_active);
      }
    },
    goNext: function () {
      console.log(this.pageNo, this.pageSize, this.tab3_active);
      if (this.org3List.length >= this.pageSize) {
        this.pageNo = this.pageNo + 1;
        console.log("next", this.pageNo);
        this.changeTab3Tab(this.tab3_active);
      }
    },
    /**
     * 音频播放
     */
    playControl(index, status = 0) {
      let urlArr = [
        "zxSuperviseSMoney/getvoice", // 资金
        "zxSuperviseSAssets/getvoice", // 资产
        "zxSuperviseSResources/getvoice", // 资源
      ];

      this.audioSrc = "";
      const that = this;
      const info = this.org3List[index];
      this.axios
        .post(BASE.genUrl(urlArr[status], { id: info.id }))
        .then(function (res) {
			console.log("音频：",res)
          const arr = that.org3List.filter((e, i) => {
            if (i == index) {
              e.playStatus = !e.playStatus;
              if (e.playStatus) that.audioSrc = res.data.result;
            } else {
              e.playStatus = false;
            }
            return e;
          });
          that.org3List = arr;
        });
    },
  },
};
</script>
<style scoped>
.org_list_img {
  width: 33.3333%;
  display: inline-block;
}
.tab3-top-new-table-1 ul,
.list-box {
  border: 1px solid #ccc;
  font-size: 12px;
  text-align: left;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 0.2rem;
}

.tab3-top-new-table-1 ul span,
.list-box span {
  color: #ccc;
}

.tab3-top-new-table-1 ul .title,
.list-box .title {
  font-size: 14px;
  color: black;
  font-weight: bold;
}

.tab3-top-new-table-1 ul .money,
.list-box .money {
  color: red;
  font-weight: bold;
}

.tab3-top-new-table-1 ul .tousu_btn,
.list-box .tousu_btn {
  display: block;
  float: right;
  border: 1px solid #b51414;
  padding: 0.1rem;
  color: #b51414;
}

.imglist {
  overflow-x: hidden;
  white-space: nowrap;
}

.tab3-top-new-table-1 ul .imglist {
  overflow-x: hidden;
  white-space: nowrap;
}

.tab3-top-new-table-1 table {
  width: 100%;
  margin-bottom: 0.5rem;
  border: 1px solid blue;
}

.tab3-top-new-table-1 tr.title {
  background: #ccc;
}

.tab3-top-new-table-1 td {
  width: 33%;
  border: 1px solid #ccc;
  font-size: 14px;
  /* color: #fe9b4a; */
}

#tab2_tab1,
#tab2_tab2 {
  display: none;
}

.tab2_tab_active {
  display: block !important;
}

.more {
  text-align: right;
}

.orgfundlist {
  padding: 0.2rem;
  text-align: left;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
}

.container #tab3 .orgfundlist .active {
  color: #fe9b4a;
  background: #b51414;
}

.container #tab2_tab2 .area3List a,
.container #tab3 .area3List a {
  color: #fe9b4a;
  font-size: 12px;
}

.container #tab3 .area2List,
.container #tab2_tab2 .area2List,
.container #tab2_tab2 .area4List,
.container #tab2 .org3Info,
.container #tab3 .area4List {
  background: #ccc;
  color: #b51414;
  border-radius: 0.5rem;
}

.container #tab2_tab2 .area4List,
.container #tab3 .area4List {
  color: black;
}

.list .zhengce-select {
  display: flex;
  flex-direction: row;
}

.list .zhengce-select-div {
  width: 100%;
}

.list .zhengce-select-div select {
  text-align: center;
  width: 100%;
  font-size: 16px;
  padding: 0.5rem 0;
  margin-top: 1rem;
  border-radius: 0.5rem;
  border: 1px solid #b51414;
}

.item-content {
  padding: 0.5rem 0;
}

.item-inner {
  display: flex;
  flex-direction: row;
}

.item-title {
  padding-right: 0.1rem;
  font-size: 14px;
  width: 85%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.item-after {
  text-align: right;
  width: 15%;
}

#tab2 .tab2-top-new a {
  width: 30%;
  display: inline-block;
  padding: 0.5rem 0;
  margin-bottom: 0.5rem;
  margin-right: 0.1rem;
  font-size: 14px;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  background-image: linear-gradient(0deg, #c82214 51%, #ed872e 95%);
  color: #fff;
  font-weight: bold;
}

#tab3 .tab3-top-new a {
  width: 23%;
  display: inline-block;
  padding: 0.5rem 0;
  margin-bottom: 0.5rem;
  margin-right: 0.1rem;
  font-size: 14px;
  background: #ccc;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  background-image: linear-gradient(0deg, #c82214 51%, #ed872e 95%);
  color: #fff;
}

#tab3 .tab3-top-2-new a {
  width: 48%;
  display: inline-block;
  padding: 0.5rem 0;
  margin-bottom: 0.5rem;
  margin-right: 0.1rem;
  font-size: 14px;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  background-image: linear-gradient(0deg, #c82214 51%, #ed872e 95%);
  color: #fff;
}

#tab2 .tab2-top-new a.active,
#tab3 .tab3-top-new a.active,
#tab3 .tab3-top-2-new a.active {
  background: #fff;
  color: #b51414;
  border: 1px solid #ccc;
  border-bottom: 0;
}

#tab2_tab2 .tab3-top,
#tab3 .tab3-top,
#tab2 .tab2-top {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
}

#tab2_tab2 .tab3-middle,
#tab3 .tab3-middle,
#tab2 .tab2-middle {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
}

#tab2_tab2 .item,
#tab3 .item,
#tab2 .item {
  flex: 0 0 32%;
  display: flex;
  flex-direction: row;
  justify-content: center; /* 水平居中*/
  align-items: center; /* 垂直居中*/
  margin-right: 0.1rem;
  margin-bottom: 0.1rem;
}

#tab2 .item .l {
  width: 50%;
  display: block;
}

#tab2 .item .r {
  border-radius: 0.5rem;
  font-size: 12px;
  background: #fff;
  display: block;
}

#tab2 .item .r h3 {
  margin: 0;
  padding: 0;
}

#tab2 .item .r div {
  padding: 0 0.2rem;
}

#tab2 .info-item .t {
  font-weight: bold;
  font-size: 14px;
}

#tab2 .info-div,
#tab2_tab2 .info-div,
#tab3 .info-div {
  flex: 1;
  width: 48%;
  min-width: 48%;
  max-width: 48%;
  /* 0 0 50%; */
  /* display: flex; */
  margin-right: 0.1rem;
  margin-top: 20px;
}

#tab2 .info-title,
#tab2 .info-item,
#tab2_tab2 .info-title,
#tab2_tab2 .info-item,
#tab3 .info-title,
#tab3 .info-item {
  text-align: center;
}

#tab2_tab2 .info-items,
#tab2 .info-items,
#tab3 .info-items {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  padding: 0.3rem 0;
  font-size: 12px;
  border: 1px solid #999;
  border-top: 0;
  color: #999;
}

#tab2_tab2 .info-title,
#tab2 .info-title,
#tab3 .info-title {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  padding: 0.3rem 0;
  font-size: 12px;
  color: #fff;
  background: #b51414;
}

#tab2 .item,
#tab2_tab2 .item,
#tab3 .item {
  font-size: 12px;
  border-radius: 0.5rem;
  color: #fe9b4a;
  background: #b51414;
  text-align: center;
  padding: 0.5rem 0;
}

.footer {
  color: #fe9b4a;
  text-align: center;
  font-size: 12px;
}

.list {
  margin-top: 1rem;
  padding: 1rem 0.8rem;
  font-size: 14px;
}

.list ul:before {
  background: none;
}

.list select {
  width: 45%;
  font-size: 12px;
  padding: 0.5rem 0;
  margin-top: 1rem;
  margin-right: 0.1rem;
  border-radius: 0.5rem;
  border: 1px solid #b51414;
}

.news-bg {
  background: url("../assets/tab-bg.png") center top no-repeat;
  background-size: 60%;
  position: absolute;
  width: 100%;
  top: -5px;
  font-weight: bold;
  color: #fff;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em 0;
}

.news {
  background: #fff;
  padding-top: 0.5rem;
  position: relative;
  border-radius: 0.5rem;
  margin-top: 1.5rem;
}

ol,
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.payinfo {
  margin-top: 0.5rem;
  display: flex;
  border-radius: 0.5rem;
  background-image: linear-gradient(0deg, #c82214 51%, #ed872e 95%);
}

.payinfo .payinfo-item {
  flex-grow: 1;
  display: flex;
  /* padding: .5rem; */
}

.payinfo .payinfo-item .payinfo-item-l {
  /* flex-grow: 1; */
  display: flex;
  width: 30%;
  justify-content: center; /* 水平居中*/
  align-items: center; /* 垂直居中*/
}

.payinfo .payinfo-item .payinfo-item-l img {
  width: 50%;
}

.payinfo .payinfo-item .payinfo-item-r {
  color: #fff;
  padding: 1rem 0;
  text-align: left;
  font-size: 14px;
  /* width: 66%;
  float: left; */
}

.payinfo .payinfo-item .payinfo-item-r li {
  text-align: center;
}

.payinfo .payinfo-item dd,
.payinfo .payinfo-item dt {
  text-align: left;
}

.container .nav-tabs {
  display: flex;
}

.container .nav-tabs a.tab-link-active {
  color: #b51414;
  background: #fff;
  /* background: url('../../static/choose-bg.png') 100% 100% no-repeat; */
}

.container .nav-tabs .tab1-link,
.container .nav-tabs .tab2-link {
  margin-right: 2px;
}

.container .nav-tabs a {
  /* width: 33%; */
  font-size: 14px;
  color: #fff;
  flex-grow: 1;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  min-height: 35px;
  line-height: 35px;
  font-weight: bold;
  /* background: #b51414; */
  background-image: linear-gradient(0deg, #c82214 51%, #ed872e 95%);
}

.container .news .list {
  text-align: left;
  padding: 0.8rem;
}

.container .tabs #tab1 input.sfz,
.container .news input.sfz {
  border: 1px solid #b51414;
  font-size: 1rem;
  padding: 0.6rem 0;
  border-radius: 0.5rem;
  width: 100%;
  margin: 0.5rem 0;
  text-align: center;
}

.container .news button,
.container #tab1 button {
  background: #b51414;
  border: 0;
  color: #fff;
  font-size: 1rem;
  padding: 0.6rem 0;
  border-radius: 0.5rem;
  width: 100%;
  margin: 0.5rem 0;
  text-align: center;
}

.container #tab1 .title {
  display: block;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
}

.container .tabs {
  background: #fff;
  padding: 0.5rem;
  border-radius: 0 0 0.5rem 0.5rem;
}

.banner {
  width: 100%;
  height: auto;
}

.page {
  background: #b51414;
}

.search {
  margin-top: 10px;
  padding: 10px;
  background: #f7f7f7;
  border-radius: 0.5rem 0.5rem;
  text-align: center;
}

.search span {
  font-size: 14px;
  display: block;
  text-align: center;
  color: #999;
  margin-bottom: 20px;
}

.no-data {
  font-weight: bold;
  font-size: 14px;
}

/**乡村振兴项目**/
.box {
  background-color: #f4f4f4;
}
.panel-custom {
  margin: 0.5rem;
  padding: 0.5rem;
  text-align: left;
  background-color: #fff;
  border-radius: 1rem;
  border: none;
}
.panel-header {
  padding: 10rpx 0;
  color: #323233;
  /* font-size: 1rem; */
  font-size: 14px;
  /* line-height: 2.3rem; */
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  font-weight: 600;
  border: none;
  display: flex;
}
.panel-header .header-left {
  flex: 1;
}
.panel-header .header-right {
  color: #b51414;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
}
.cell-value {
  flex: 2;
}

.cell-group-title-custom {
  padding: 10rpx 0;
  color: #323233;
  /* font-size: 1rem; */
  font-size: 14px;
  line-height: 2.3rem;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  font-weight: 600;
  border: none;
}
.cell-group-title-custom span {
  padding-left: 0.5rem;
  border-left: 3px solid #b51414;
}

.van-cell {
  padding: 0.2rem 1rem;
}
</style>
